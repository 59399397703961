import { SET_PAYSLIPS_DATA } from "./actions";
const initialState = {
  payslipsData: [],
  loader: false,
  payslipUploadStatus: "",
  otherDocUploadStatus: "",
};

const payslips = (state = initialState, { type, payload }) => {
 
  switch (type) {
    case SET_PAYSLIPS_DATA:
      return { ...state, payslipsData: [...payload] };
    case "SET_LOADER_PAYSLIP":
      return { ...state, loader: payload };
    case "SET_OTHER_DOC_UPLOAD_STATUS":
      return { ...state, otherDocUploadStatus: payload };
    case "SET_PAYSLIP_UPLOAD_STATUS":
      return { ...state, payslipUploadStatus: payload };

    default:
      return state;
  }
};
export default payslips;

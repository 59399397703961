import axios from "axios";
import { apiBaseUrl } from "./apis";
import axiosRetry from "axios-retry";
import localStorageConstants from "src/constants/localstorageConstants";
import { store } from "src/redux/store";
import { logout } from "src/utils/system";
import { getTimeDiff } from "src/utils/getTimeDiff";

const apiInstance = () => {
  const api = axios.create({
    baseURL: apiBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {
    let accessToken = localStorage.getItem(localStorageConstants.accessToken);
    const organization_id = localStorage.getItem(localStorageConstants.organizationId);
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
      if (organization_id) {
        if (config.method !== 'OPTIONS') {
          config.headers.organization_id = `${organization_id}`;
        }
      }
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => {
      if (localStorage?.getItem("userId")) {
        if (response.data?.status === "logout") {
          store.dispatch(logout());
        }

        var lastApiCall = localStorage.getItem("lastApiCall");

        if (
          lastApiCall &&
          getTimeDiff({
            startTime: new Date(lastApiCall),
            endTime: new Date(),
          }) >= 15
        ) {
          store.dispatch(logout());
        } else {
          localStorage.setItem("lastApiCall", new Date().toString());
        }
      }
      return response;
    },
    (error) => {
      throw error;
    }
  );

  return api;
};

const apiClient = apiInstance();

export default apiClient;

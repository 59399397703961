const baseUrlWithSlash = process.env.REACT_APP_API_BASE_URL;
// process.env.REACT_APP_API_BASE_URL;
// "http://3.109.120.247:443/";
// const baseUrlWithSlash = "http://localhost:82/";
// "http://3.108.91.182:443/";

const apiUrls = {
  auth: {
    login: "api/auth/v1/login",
    generateOtp: "api/auth/v1/getOtp",
    updateSession: "api/auth/v1/updateSession",
    verifyOtp: (query) => `api/auth/v1/verifyOtp${query}`,
  },
  userDocuments: {
    upload: "api/userDocument/v1",
    getUserDocuments: (userId) => `api/userDocument/v1?user_id=${userId}`,
    deleteUserDocument: (id) => `api/userDocument/v1?_id=${id}`,
  },
  organization: {
    addOrganization: "api/organization/v1",
    getOrganizations: (queryString) => `api/organization/v1${queryString}`,
    importOrganizations: "api/organization/v1/import",
    exportOrganizations: (type) => `api/organization/v1/export?type=${type}`,
    getOrganizationsPaginate: (page, limit) =>
      `api/organization/v1/paginate?page=${page}&limit=${limit}`,
    updateOrganization: (_id) => `api/organization/v1?_id=${_id}`,
    deleteOrganization: (_id) => `api/organization/v1/delete?_id=${_id}`,
    disableEmployee: (_id) => `api/organization/v1/disable?_id=${_id}`,
    changeStatus: (status, id) =>
      `/api/organization/v1/status?_id=${id}&status=${status}`,
    addLogo: "/api/organization/v1/uploadOrganizationPicture",
  },
  stats: {
    getAllStats: (query) => `api/orgStats/v1/getAllStats?monthYear=${query}`,
  },
  employee: {
    downloadEmployees: "api/user/v1/download",
    resetPassword: (userId) => `api/user/v1/resetPassword?_id=${userId}`,
    addEmployee: "api/user/v1",
    getEmployees: (queryString) => `api/user/v1${queryString}`,
    getUserPassword: (id) => `api/user/v1/getUserPassword?_id=${id}`,
    updateEmployee: "api/user/v1",
    changeStatus: (status, id) =>
      `/api/user/v1/status?_id=${id}&status=${status}`,
    importEmployees: "api/user/v1/import/excel",
    importGmtEmployees: "api/user/v1/import-gmt/excel",
    exportEmployees: (type) => `api/user/v1/export?type=${type}`,
    getEmployeePaginate: (query) => `api/user/v1/paginate${query}`,
    changePassword: "api/user/v1/changePassword",
    isEmployeeExist: (key, value, _id) => {
      return `api/user/v1/isEmployeeExist?${key}=${value}${
        _id ? `&_id=${_id}` : ""
      }`;
    },
    getEmployeesForManagers: `api/user/v1/getEmployeesForManagers`,
    getUsersFromIdList: "api/user/v1/getFavourites",
    getMetaData: (query) => `api/user/v1/meta${query}`,
    addMetaData: `api/user/v1/meta`,
    getEmployeesDocuments: (query) => `api/userDocument/v1/get/all${query}`,
    addFavourite: `api/user/v1/favourites/add`,
    removeFavourite: `api/user/v1/favourites/remove`,
    uploadProfilePicture: "api/user/v1/profile_picture",
    getUsersWithSelectedFields: "api/user/v1/getUsersWithSelectedFields",
    getMyTeam: (query) => `api/user/v1/getMyTeam${query}`,
    getEmployeeAttendance: (query) => `api/attendance/v1/getOne${query}`,
    editEmployeeAttendance: "api/attendance/v1/edit",
    massUploadAttendance: "/api/attendance/v1/upload",
    massUploadLeave: "/api/leave/apply/v1/massUpload",
  },
  user: {
    findOne: (id) => `api/user/v1/getuser?_id=${id}`,
  },
  asset: {
    addAssets: "api/asset/v1",
    getAssets: (query) => `api/asset/v1${query}`,
    deleteAssets: (_id) => `api/asset/v1?_id=${_id}`,
    importAssets: "api/asset/v1/upload",
  },
  payslip: {
    getPayslips: `api/payslip/v1/getPayslips`,
    getOnePayslip: `api/payslip/v1/getOne`,
    updatePayslip: `api/payslip/v1/updatePayslip`,
    getPayslipsByUserId: `api/payslip/v1/getUserPayslips`,
    deletePayslips: (_id, fileType, filePath) =>
      `api/payslip/v1?_id=${_id}&fileType=${fileType}&filePath=${filePath}`,
    uploadPayslip: `/api/payslip/v1`,
    massUploadPayslip: `/api/payslip/v1/import`,
    massUploadPdf: `/api/payslip/v1/createPdf`,
    deleteMonthlyPayslips: "api/queries/v1/deletePayslips",
  },
  leaves: {
    getLeaves: (query) => `api/leave/v1?is_deleted=false${query}`,
    createLeave: `api/leave/v1`,
    appliedLeaves: (query) => `api/leave/apply/v1${query}`,
    getLeaveStats: (query) => `api/leave/v1/stat${query}`,
    getLeaveStatsV2: (query) => `api/leave/v2/stat${query}`,
    getLeaveTypes: (query) => `api/leave/v1${query}`,
    applyForLeave: `api/leave/apply/v1`,
    getEmployeeLeaves: (query) => `api/leave/v1/getEmployeeLeaves${query}`,
    uploadLeave: `api/leave/v1/upload`,
  },
  holidays: {
    getHolidays: (queryString) => `api/holiday/v1?${queryString}`,
    createHoliday: `api/holiday/v1`,
  },
  resignation: {
    getResignationList: (queryString) => `api/resignation/find?${queryString}`,
  },
  otherDocuments: {
    uploadOtherDocs: `api/otherDocs/v1/`,
  },
  getPresignedUrl: `api/userDocument/v1/getUrl`,
  downloads: {
    employeeUploadSampleSheet: `api/downloads/v1/employeeUploadSampleSheet/employee`,
    assetUploadSampleSheet: `api/downloads/v1/employeeUploadSampleSheet/asset`,
  },
  activities: {
    getActivities: (query) => `api/activities/v1?${query}`,
    addActivity: `api/activities/v1`,
  },
  resignation: {
    getResignations: (query) => `api/resignation/v1/find?${query}`,
    getEmployee: (query) => `api/resignation/v1/findOne?${query}`,
    putResignation: `api/resignation/v1/add`,
    addResignations: `api/resignation/v1/add`,
  },
  documentCenter: {
    addQuiz: `api/organizationDocuments/v1`,
    downloadQuizReport: `api/organizationDocuments/v1/downloadQuizReport`,
    getQuizDoc: (query) => `api/organizationDocuments/v1/get?${query}`,
    getQuiz: (query) => `/api/quiz/v1/get?${query}`,
    submitQuiz: "api/quiz/v1/submitQuiz",
    getResponse: (queryString) => `/api/quiz/v1/getResponse${queryString}`,
  },
  companyConfigs: {
    addDynamicKeys: "/api/companyConfigs/v1/addDynamicKeys",
    getCompanyConfigs: (query) => `api/companyConfigs/v1/get?${query}`,
    addCompanyConfigs: "api/companyConfigs/v1/add",
    addOfficeHours: "api/companyConfigs/v1/addOfficeHours",
    getOfficeHours: (query) => `api/companyConfigs/v1/getOfficeHours?${query}`,
  },
  dashboard: {
    addTiming: "api/attendance/v1/add",
    getTiming: (query) => `api/attendance/v1/getOne${query}`,
  },
  attendanceTab: {
    get: "api/attendance/v1/getAll",
  },
  uploadFile: "api/files/v1/uploadFile",

  employeeAttendance: {
    add: "api/attendance/v3/add",
    get: "api/attendance/v1/getOverTimeReport",
    getAttendanceSummary: "api/attendance/v2/summary",
    getAttendanceSummaryV3: "api/attendance/v3/summary",
    getAttendanceSummaryDetailed: "api/attendance/v3/detailedSummary",
    getV2: "api/attendance/v2/get",
    editV2: "api/attendance/v2/edit",
    editV3: "api/attendance/v3/edit",
    deleteV2: "api/attendance/v2/delete",
    getAttendanceSummaryNeumaric: "api/attendance/v3/neumericSummary",
    dailyReport: "api/attendance/v1/dailyReport",
    getV3: "api/attendance/v3/get",
  },
  employeeAttendanceApprove: {
    accept: "api/attendance/v2/accept",
    reject: "api/attendance/v2/reject",
    acceptAll: "",
  },
  salaryDetails: {
    add: "api/salaryDetails/v1/add",
    get: `api/salaryDetails/v1/find`,
  },
  salaryReport: {
    getSalaryDetails: "api/salary/v1/getReport",
  },
  PlanDetails: {
    get: (queryString) => `/api/companyConfigs/v1/getPlanDetails${queryString}`,
  },
  OrganizationKeyValueOptions: {
    get: (queryString) =>
      `/api/organizationKeyValueOptions/v1/get${queryString}`,
  },
  reports: {
    employeeLeaves: "/api/reports/v1/employeeLeaves",
  },
  notification: {
    list: "/api/notification/v1/get",
    listV2: "/api/notification/v2/get",
    add: "/api/notification/v1/add",
    count: "/api/notification/v1/unreadCount",
    update: "/api/notification/v1/update",
    readAll: "/api/notification/v1/readAll",
    deleteAll: "/api/notification/v1/deleteAll",
  },

  notificationAdmin: {
    /*   getHolidays: (queryString) => `api/holiday/v1?${queryString}`, */
    createNotification: `api/notification/v1/add`,
  },
  statistics: {
    get: (query) => `/api/stats/v1/get${query}`,
  },
  cornjobLogs: {
    getcornjobLogs: "/api/cronjob/getLogs",
  },
  apiLogs: {
    getApiLogs: "/api/logs/v1/getLogs",
  },
  practitionerPersons: {
    addPractitionerPersons:
      "/api/practitionerPersons/v1/addPractitionerPersons",
    getPractitionerPersons:
      "/api/practitionerPersons/v1/getPractitionerPersons",
    listPractitionerPersons:
      "/api/practitionerPersons/v1/listPractitionerPersons",
  },
  lateMarkConfig: {
    addLateMarkConfig: "/api/lateMarkConfig/add",
    getLateMarkConfig: (id) => `/api/lateMarkConfig/get?organization_id=${id}`,
  },
};

export { baseUrlWithSlash as apiBaseUrl };
export default apiUrls;

import { combineReducers } from "redux";
import auth from "src/views/auth/reducer";
import payslips from "src/views/payslip/reducers";
import sidebar from "src/views/controlCenter/reducer";
import { LOGOUT } from "./actions";
import commonReducer from "./common-reducer";

const appReducer = combineReducers({
  commonReducer: commonReducer,
  auth: auth,
  payslips: payslips,
  sidebar: sidebar,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

const logout = () => () => {

  localStorage.clear();
  window.location.reload();
};

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'long' });
}


export { logout,getMonthName };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Loader from "./components/loader";
import "./scss/style.scss";
import "./index.css";
import { getUserDetails, updateSession } from "./views/auth/login/apis";
import authRoutes from "./routes/authRoutes";
import { CFade } from "@coreui/react";
import {
  SET_KEY_VALUE_OPTIONS,
  SET_LOADER,
  SET_SHOW_COMMON_MODAL,
} from "./redux/actions";
import CustomModal from "./components/modal";
import localStorageConstants from "./constants/localstorageConstants";
import { logout } from "./utils/system";
import Location from "./views/pages/location";
import ScrollToTop from "./components/scrollToTop";
import { getKeyValueOptions } from "./views/employee/add/api";
import { firebaseAnalytics } from "./firebase-config";

const Loading = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};

const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const App = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loader);
  const companyConfigs = useSelector((state) => state?.auth?.companyConfigs);
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  var user_id = localStorage?.getItem(localStorageConstants?.userId);
  const { organization_id } = useSelector((state) => state.auth?.userDetails);

  useEffect(() => {
    getUserLocation();
    var session_id = localStorage?.getItem(localStorageConstants?.sessionId);
    if (session_id) {
      updateSession(
        {
          user_id: user_id,
          session_id,
          device_type: "Web",
          expired: false,
        },
        (data) => {
          if (data?.status === "success") {
            dispatch({ type: SET_LOADER, payload: true });
            dispatch(
              getUserDetails(
                () => {
                  dispatch({ type: SET_LOADER, payload: false });
                },
                () => {
                  dispatch({ type: SET_LOADER, payload: false });
                }
              )
            );
          } else {
            dispatch(logout());
          }
        }
      );
    }
  }, []);
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setLocationCordinates({
          lat: pos.coords.latitude,
          long: pos.coords.longitude,
        });
      });
    } else {
      alert("cc");
    }
  };
  const [locationCordinates, setLocationCordinates] = useState({
    lat: "",
    long: "",
  });
  const commonModalContent = useSelector(
    (state) => state.commonReducer.commonModalContent
  );
  const isCommonModalVisible = useSelector(
    (state) => state.commonReducer.isCommonModalVisible
  );

  // useEffect(() => {
  //   firebaseAnalytics.logEvent("hello");
  // }, []);

  if (
    userDetails?._id &&
    (userDetails?.user_type === "employee" ||
      userDetails?.user_type === "hr") &&
    companyConfigs?.is_location_for_attendance_enabled === true &&
    !locationCordinates.lat &&
    !locationCordinates.long
  ) {
    return <Location />;
  }

  return (
    <>
      <Loader loading={loading} />
      <ScrollToTop />
      <Switch>
        {authRoutes.map((route, idx) => {
          return (
            route.component && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) => (
                  <CFade>
                    <route.component {...props} />
                  </CFade>
                )}
              />
            )
          );
        })}
        <Route
          path="/"
          name="Home"
          render={(props) => <TheLayout {...props} />}
        />
      </Switch>
      <CustomModal
        setIsModalVisible={() => {
          dispatch({ type: SET_SHOW_COMMON_MODAL, payload: false });
        }}
        title={commonModalContent?.title}
        innerText={commonModalContent?.innerText}
        isModalVisible={isCommonModalVisible}
        primaryCtaFunction={() => {
          dispatch({ type: SET_SHOW_COMMON_MODAL, payload: false });
        }}
        primaryText={commonModalContent?.primaryText}
      />
    </>
  );
};

export default App;

export { Loading };

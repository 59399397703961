import settings from "./settings.svg";
import email from "./email.svg";
import avatar from "./avatar.svg";
import password from "./password.svg";
import alert from "./warning.svg";
import gst from "./gst.svg";
import printer from "./printer.svg";
import chatIcon from "./chat.svg";

const Images = {
  settings: settings,
  email: email,
  avatar: avatar,
  password: password,
  alert: alert,
  gst: gst,
  printer: printer,
  chatIcon: chatIcon,
};

export default Images;

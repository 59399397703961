import {
  SET_USER_DATA,
  SET_META_DATA,
  SET_COMPANY_CONFIGS,
  SET_NOTIFICATION_DATA,
} from "./actions";

const initialState = {
  userDetails: {},
  userMetaData: {},
  companyConfigs: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, userDetails: action.payload };

    case SET_META_DATA:
      return { ...state, userMetaData: action.payload };

    case SET_COMPANY_CONFIGS:
      return { ...state, companyConfigs: action.payload };
    case SET_NOTIFICATION_DATA:
      return { ...state, notify: action.payload };

    default:
      return state;
  }
};
export default auth;

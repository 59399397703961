import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { Loading } from "./App";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
// import * as firebase from "firebase/app";
// import { firebaseConfig } from "./firebase-config";
// import FirebaseAnalytics from "./components/firebaseAnaytics";

React.icons = icons;

// firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <SnackbarProvider maxSnack={3} preventDuplicate>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="">
          {/* <FirebaseAnalytics /> */}
          <React.Suspense fallback={() => <Loading loading={true} />}>
            <App />
          </React.Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </SnackbarProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();

import passwordImage from "./password.jpg";
import nullProfileImage from "./nullProfileImage.png";
import brandLogo from "./appLogo.png";
import SearchLocation from "./search-location.png";
import RedArrow from "./redArrow.png";

import Help2 from "./help2.jpg";

const Images = {
  passwordImage: passwordImage,
  nullProfileImage: nullProfileImage,
  brandLogo: brandLogo,
  Help2: Help2,
  SearchLocation,
  RedArrow,
};

export default Images;

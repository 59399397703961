import {
  SET_CONTROL_CENTER,
  SET_KEY_VALUE_OPTIONS,
  SET_LOADER,
  SET_SHOW_COMMON_MODAL,
  SET_SHOW_COMMON_MODAL_CONTENT,
} from "./actions";
const initialState = {
  sidebarShow: "responsive",
  loader: false,
  isCommonModalVisible: false,
  commonModalContent: {},
  controlCenter: false,
};

const commonReducer = (state = initialState, { type, payload, ...rest }) => {

  switch (type) {
    case "set":
      return { ...state, ...rest };

    case SET_LOADER:
      return { ...state, loader: payload };

    case SET_SHOW_COMMON_MODAL: {
      return { ...state, isCommonModalVisible: payload };
    }

    case SET_SHOW_COMMON_MODAL_CONTENT: {
      return { ...state, commonModalContent: payload };
    }
    case SET_CONTROL_CENTER: {
      return { ...state, controlCenter: payload };
    }
    case SET_KEY_VALUE_OPTIONS: {
      return { ...state, keyValueOptions: payload };
    }
    default:
      return state;
  }
};
export default commonReducer;

import localStorageConstants from "src/constants/localstorageConstants";
import { SET_USER_DATA } from "../actions";

const { default: apiClient } = require("src/apis/api-client");
const { default: apiUrls } = require("src/apis/apis");

const login = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(apiUrls.auth.login, dataToSend);

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getUserDetails =
  (successCallback = () => {}, failCallback = () => {}) =>
  async (dispatch) => {
    let userId = await localStorage.getItem(localStorageConstants.userId);
    try {
      const { data = {} } = await apiClient.get(apiUrls.user.findOne(userId));

      successCallback(data);
      dispatch({ type: SET_USER_DATA, payload: data?.data });
    } catch (err) {
      failCallback();
    }
  };
const generateOtp = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.auth.generateOtp,
      dataToSend
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const verifyOtp = async (
  query,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(apiUrls.auth?.verifyOtp(query));
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getMetaData = async (
  queryString,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls?.employee?.getMetaData(queryString)
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const addMetaData = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls?.employee?.addMetaData,
      dataToSend
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const updateSession = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls?.auth?.updateSession,
      dataToSend
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getCompanyConfigs = async (
  queryString,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls?.companyConfigs?.getCompanyConfigs(queryString)
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getOfficeHours = async (
  queryString,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls?.companyConfigs?.getOfficeHours(queryString)
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const uploadFile = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    let { file, uploadPath, _id } = dataToSend;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uploadPath", uploadPath);
    if (_id) {
      formData.append("_id", _id);
    }
    const { data = {} } = await apiClient.post(apiUrls.uploadFile, formData);
    successCallback(data);
    return data;
  } catch (err) {
    failCallback();
  }
};

export {
  login,
  getUserDetails,
  getMetaData,
  addMetaData,
  generateOtp,
  verifyOtp,
  updateSession,
  getCompanyConfigs,
  getOfficeHours,
  uploadFile,
};

import React from "react";
import Images from "src/constants/images";
import "./styles.css";

const Location = () => {
  return (
    <div className="location-container">
      <p className="location-text">
        To access web portal please enable the location
      </p>
      <p className="location-text-2 mt-4">
        If popup to enable location does not appear, follow these steps:
      </p>
      <div style={{ position: "relative", width: "85%" }}>
        <img
          src={Images.SearchLocation}
          height={100}
          style={{ width: "100%", marginTop: 20 }}
        />
        <img
          src={Images.RedArrow}
          style={{
            position: "absolute",
            bottom: -85,
            right: 35,
            height: 90,
            width: 90,
          }}
        />
      </div>

      <ul style={{ fontSize: 18, marginTop: 50 }}>
        <li>Click on the icon shown in the above image.</li>
        <li>Select Always Allow Option.</li>
        <li>Click Done.</li>
        <li>Reload The Page.</li>
      </ul>
    </div>
  );
};

export default Location;

const { default: apiClient } = require("src/apis/api-client");
const { default: apiUrls } = require("src/apis/apis");

const addEmployee = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.employee.addEmployee,
      dataToSend
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const changeStatus = async (
  _id,
  status,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log(status, "data status");
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.employee.changeStatus(status, _id)
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getEmployeesForManagers = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.employee.getEmployeesForManagers,
      dataToSend
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const isEmployeeExist = async (
  key,
  value,
  context,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.employee.isEmployeeExist(key, value, context?.parent?._id)
    );

    return data.isValid;
  } catch (err) {
    failCallback();
  }
};

const getEmployees = async (
  queryString,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.employee.getEmployees(queryString)
    );

    successCallback(data);
    return data;
  } catch (err) {
    failCallback();
  }
};

const addAssets = async (dataToSend, callBack = () => {}) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.asset.addAssets,
      dataToSend
    );

    callBack(null, null);
  } catch (err) {
    callBack(err, err);
  }
};

const getAssets = async (
  query,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(apiUrls.asset.getAssets(query));

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const deleteAssets = async (
  _id,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.delete(
      apiUrls.asset.deleteAssets(_id)
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const updateEmployee = async (
  _id,
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.patch(
      `${apiUrls.employee.updateEmployee}?_id=${_id}`,
      dataToSend
    );

    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const uploadUserDocument = async (userId, item, callBack = () => {}) => {
  var formData = new FormData();

  if (item?.image) {
    formData.append("image", item?.image);
  }
  if (item?.type) {
    formData.append("type", item?.type);
  }
  formData.append("user_id", userId);
  if (item?.title) {
    formData.append("title", item?.title);
  }
  if (item?._id) {
    formData.append("_id", item?._id);
  }
  if (item?.number) {
    formData.append("number", item?.number);
  }
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.userDocuments?.upload,
      formData
    );

    callBack(null, data);
  } catch (err) {
    callBack(err, err);
  }
};

const getUserDocuments = async (
  userId,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.userDocuments?.getUserDocuments(userId)
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getUserPassword = async (
  _id,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.get(
      apiUrls.employee?.getUserPassword(_id)
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const getPresignedUrl = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.getPresignedUrl,
      dataToSend
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const deleteUserDocument = async (
  id,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.delete(
      apiUrls.userDocuments.deleteUserDocument(id)
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};

const uploadProfilePicture = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    var { user_id, image } = dataToSend;
    var formData = new FormData();
    if (user_id && image) {
      formData?.append("image", image);
      formData?.append("user_id", user_id);
    }
    const { data = {} } = await apiClient.post(
      apiUrls.employee?.uploadProfilePicture,
      formData
    );
    successCallback(data);
  } catch (err) {
    failCallback();
  }
};
const getKeyValueOptions = async (
  queryString,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    // const queryString = `?organization_id=${organization_id}&key=${key}`;
    const { data = {} } = await apiClient.get(
      apiUrls.OrganizationKeyValueOptions.get(queryString)
    );
    successCallback(data);
  } catch (error) {
    failCallback();
  }
};
export {
  deleteUserDocument,
  uploadUserDocument,
  getUserDocuments,
  addEmployee,
  addAssets,
  getAssets,
  deleteAssets,
  getEmployeesForManagers,
  isEmployeeExist,
  changeStatus,
  updateEmployee,
  getUserPassword,
  getPresignedUrl,
  getEmployees,
  uploadProfilePicture,
  getKeyValueOptions
};

import Images from "src/constants/images/sidebar";
import { SET_ACTIVE_MENU_TAB } from "./actions";

const initialState = {
  activeMenuTab: { name: "Leaves", icon: Images.settings },
};

const sidebar = (state = initialState, action) => {
 
  switch (action.type) {
    case SET_ACTIVE_MENU_TAB:
      return { ...state, activeMenuTab: action.payload };

    default:
      return state;
  }
};
export default sidebar;

import React from "react";
import Location from "src/views/pages/location";
const Login = React.lazy(() => import("../views/auth/login/index"));
const ChangePassword = React.lazy(() => import("../views/auth/changePassword"));
const ForgotPassword = React.lazy(() => import("../views/auth/forgotPassword"));
const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));

const authRoutes = [
  { path: "/login", name: "Login Page", component: Login },
  {
    path: "/changePassword",
    name: "Change Password",
    component: ChangePassword,
  },
  { path: "/resetPassword", name: "Reset Password", component: ChangePassword },
  {
    path: "/forgotPassword",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  { path: "/404", name: "Not Found", component: Page404 },
  { path: "/enableLocation", name: "Location", component: Location },
];

export default authRoutes;
